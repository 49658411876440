<template>
    <v-container fluid class="tw-bg-white tw-h-screen">
        <div class="tw-grid tw-gap-4 tw-grid-cols-3" v-if="categories.length > 0">
            <div class="grid-item" :key="category.id" v-for="category in categories">
                <v-card-title class="pb-0">
                    <v-icon
                        left
                    >
                        {{ `${category.icon}` }}
                    </v-icon>
                    <span class="title">{{ category.title }}</span>
                </v-card-title>
                <v-list-item
                    v-for="article in category.articles"
                    :key="article.id"
                    :to="{name: 'articles.show', params: {id: article.slug}}"
                >
                    <v-list-item-content>
                        <v-list-item-title>{{ article.title }}</v-list-item-title>
                        <div class="tw-text-sm tw-text-gray-700 tw-mt-1">{{ article.summary }}</div>
                    </v-list-item-content>
                </v-list-item>
            </div>
        </div>
        <div v-else>No results found.</div>
    </v-container>
</template>

<script>
    import Http from '@/utils/httpClient';
    import Masonry from 'masonry-layout';

    export default {
        data() {
            return {
                categories: [],
            }
        },
        mounted() {
            this.init();
        },
        methods: {
            init() {
                this.$root.$loading.open();
                Http.get('kb?tags=public_legals')
                    .then(({ data }) => {
                        this.$root.$loading.close();
                        this.categories = data.data;

                        this.$nextTick(() => {
                            new Masonry('.tw-grid', {
                                itemSelector: '.grid-item',
                                columnWidth: 340,
                                //gutter: 10
                            })
                        });
                    })
                    .catch(() => {
                        this.$root.$loading.close();
                    })
            }
        }
    }    
</script>

<style scoped>
.grid-item {
    width: 340px;
}
</style>
